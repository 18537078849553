const chordRegex = /^[A-G][b#]?(2|5|6|7|9|11|13|6\/9|7-5|7-9|7#5|7#9|7\+5|7\+9|7b5|7b9|7sus2|7sus4|add2|add4|add9|aug|dim|dim7|m\/maj7|m6|m7|m7b5|m9|m11|m13|maj7|maj9|maj11|maj13|mb5|m|sus|sus2|sus4)*(\/[A-G][b#]*)*$/

const keys = [
    { name: 'Ab', value: 0, type: 'F' },
    { name: 'A', value: 1, type: 'N' },
    { name: 'A#', value: 2, type: 'S' },
    { name: 'Bb', value: 2, type: 'F' },
    { name: 'B', value: 3, type: 'N' },
    { name: 'C', value: 4, type: 'N' },
    { name: 'C#', value: 5, type: 'S' },
    { name: 'Db', value: 5, type: 'F' },
    { name: 'D', value: 6, type: 'N' },
    { name: 'D#', value: 7, type: 'S' },
    { name: 'Eb', value: 7, type: 'F' },
    { name: 'E', value: 8, type: 'N' },
    { name: 'F', value: 9, type: 'N' },
    { name: 'F#', value: 10, type: 'S' },
    { name: 'Gb', value: 10, type: 'F' },
    { name: 'G', value: 11, type: 'N' },
    { name: 'G#', value: 0, type: 'S' }
];

const getKeyByName = (name) => {
    if (name.charAt(name.length - 1) === "m") {
        name = name.substring(0, name.length - 1);
    }
    for (let i = 0; i < keys.length; i++) {
        if (name === keys[i].name) {
            return keys[i];
        }
    }
};

// MAIN TRANSPOSE METHOD
const transposeLyricsByMeasure = (songLine, transposeMeasure) => {
    let origKey = getFirstChordInLine(songLine);
    let transposedKey = origKey.name;
    // for negative measures (example -3)
    if (transposeMeasure < 0) {
        for (let i = transposeMeasure; i < 0; i++) {
            transposedKey = getPreviousKey(transposedKey);
        }
    } else {
        for (let i = 0; i < transposeMeasure; i++) {
            transposedKey = getNextKey(transposedKey);
        }
    }
    return transposeLine(origKey.name, transposedKey, songLine);
}

const transposeLine = (currentKey, targetKey, songLine) => {
    let chordLine = songLine;
    const startSpace = chordLine.startsWith(" ");
    if (!startSpace) {
        chordLine = " " + chordLine;
    }
    let chords = chordLine.split(/\s+/);
    let whitespace = chordLine.split(/[A-Za-z0-9#/]+/);
    let output = "";

    for (let i = 0; i < chords.length; i++) {
        // I am skipping empty string
        if (chords[i].length > 0 || !chords[i].includes("")) {
            output = output + transposeChord(currentKey, targetKey, chords[i]);;
        }
        if (i < whitespace.length) {
            output = output + whitespace[i].replace("-", "");
        }
    }

    if (!startSpace) {
        output = output.substring(1);
    }
    return output;
}

const transposeChord = (currentKey, targetKey, chord) => {
    const delta = getDelta(getKeyByName(currentKey.trim()).value, getKeyByName(targetKey.trim()).value);
    // Case when only 1 chord is in brackets (A)
    if (chord.startsWith("(") && chord.endsWith(")")) {
        chord = chord.substring(1, chord.length - 1);
        // Case when multiple chords in brackets (A...
    } else if (chord.startsWith("(") && !chord.endsWith(")")) {
        chord = chord.substring(1, chord.length);
    } // Case when multiple chords in brackets ...C)
    else if (!chord.startsWith("(") && chord.endsWith(")")) {
        chord = chord.substring(0, chord.length - 1);
    }

    let newChord = null;
    if (chord.includes("/")) {
        let parts = chord.split("/");
        let chordFirstTransposed;
        let chordFirst = parts[0];
        if ("-" === chordFirst) {
            chordFirstTransposed = chordFirst;
        } else {
            let chordRootFirstChord = getChordRoot(chordFirst);
            chordFirstTransposed = getNewChord(delta, chordFirst, chordRootFirstChord,
                targetKey);
        }
        let chordSecond = parts[1];
        let chordRootSecondChord = getChordRoot(chordSecond);
        let chordSecondTransposed = getNewChord(delta, chordSecond, chordRootSecondChord,
            targetKey);

        newChord = chordFirstTransposed + "/" + chordSecondTransposed;
    } else {
        let chordRoot = getChordRoot(chord);
        let newChordRoot = getNewKey(chordRoot, delta, targetKey.trim());
        let chordTail = chord.substring(chordRoot.length).replace("#", "");
        chordTail = chordTail.replace("b", "");
        newChord = newChordRoot.name + chordTail;
    }
    return newChord;
}

const getNewChord = (delta, chord, chordRoot, targetKey) => {
    const newChordRoot = getNewKey(chordRoot, delta, targetKey.trim());
    let chordTail = chord.substring(chordRoot.length).replace("#", "");
    chordTail = chordTail.replace("b", "");
    const newChord = newChordRoot.name + chordTail;
    return newChord;
}

const getNextKey = (key) => {
    const keyObject = getKeyByName(key);
    let nextKey = null;
    for (let i = 0; i < keys.length; i++) {
        if (keys[i] === keyObject) {
            //check if this is maybe the last element in list, then take first element
            if ((i + 1) === keys.length) {
                nextKey = keys[0].name;
            } else {
                nextKey = keys[i + 1].name;
            }
            break;
        }
    }
    return nextKey;
}

const getPreviousKey = (key) => {
    const keyObject = getKeyByName(key);
    let nextKey = null;
    for (let i = 0; i < keys.length; i++) {
        if (keys[i] === keyObject) {
            //check if this is maybe the first element, then take last
            if (i === 0) {
                nextKey = keys[keys.length - 1].name;
            } else {
                nextKey = keys[i - 1].name;
            }
        }
    }
    return nextKey;
}

/* eslint-disable no-unused-vars */
const getChordRoot = (input) => {
    if (input.length > 1 && (input.charAt(1) === "b" || input.charAt(1) === "#"))
        return input.substr(0, 2);
    else
        return input.substr(0, 1);
};

const getNewKey = (oldKey, delta, targetKey) => {
    let keyValue = getKeyByName(oldKey).value + delta;

    if (keyValue > 11) {
        keyValue -= 12;
    } else if (keyValue < 0) {
        keyValue += 12;
    }

    let i = 0;
    if (keyValue === 0 || keyValue === 2 || keyValue === 5 || keyValue === 7 || keyValue === 10) {
        // Return the Flat or Sharp Key
        switch (targetKey.name) {
            case "A":
            case "A#":
            case "B":
            case "C":
            case "C#":
            case "D":
            case "D#":
            case "E":
            case "F#":
            case "G":
            case "G#":
                for (; i < keys.length; i++) {
                    if (keys[i].value === keyValue && keys[i].type === "S") {
                        return keys[i];
                    }
                }
                break;
            default:
                for (; i < keys.length; i++) {
                    if (keys[i].value === keyValue && keys[i].type === "F") {
                        return keys[i];
                    }
                }
        }
    }
    else {
        // Return the Natural Key
        for (; i < keys.length; i++) {
            if (keys[i].value === keyValue) {
                return keys[i];
            }
        }
    }
};

const getChordType = (key) => {
    switch (key.charAt(key.length - 1)) {
        case "b":
            return "F";
        case "#":
            return "S";
        default:
            return "N";
    }
};

const getDelta = (oldIndex, newIndex) => {
    if (oldIndex > newIndex)
        return 0 - (oldIndex - newIndex);
    else if (oldIndex < newIndex)
        return 0 + (newIndex - oldIndex);
    else
        return 0;
};

//export
const isChordLine = (input) => {
    let tokens = input.replace(/\s+/, " ").split(" ");
    // Try to find tokens that aren't chords
    // if we find one we know that this line is not a 'chord' line.
    for (let i = 0; i < tokens.length; i++) {
        // match -/,(,)," " and replace them with ""
        tokens[i] = tokens[i].replace(/(\()|(\))|(-\/)|(\s+)/g, "");
        //console.log("token");
        //console.log(tokens[i]);
        // i should replace ( ) also, maybe with one regex
        if (!((tokens[i]).trim().length === 0) && !tokens[i].match(chordRegex)) {
            return false;
        }
    }
    return true;
};

const getFirstChordInLine = (input) => {
    let tokens = input.replace(/\s+/, " ").split(" ");
    // Try to find tokens that aren't chords
    // if we find one we know that this line is not a 'chord' line.
    for (let i = 0; i < tokens.length; i++) {
        // match -/,(,)," " and replace them with ""
        tokens[i] = tokens[i].replace(/(\()|(\))|(-\/)|(\s+)/, "");
        if ((tokens[i]).trim().length !== 0 && tokens[i].match(chordRegex)) {
            let tokenString = tokens[i];
            if (tokenString.indexOf("/") !== -1) {
                tokenString = tokenString.substring(0, tokenString.indexOf("/"));
            }
            tokenString = tokenString.replace(/[0-9]/g, '');
            let firstChord = getKeyByName(tokenString);
            return firstChord;
        }
    }
    // default
    let firstChord = getKeyByName("C");
    return firstChord;
};

//export 
const resolveSongContentStyle = (songContent) => {
    let line = songContent;
    let initialChordSet = false;
    let thisIsChorusText = false;
    const verseTypes = ["Verse", "Chorus", "Bridge", "Intro", "Ending"];
    let currentKey = 'C';
    let currentMasterChord = 'C';

    let lineRecognized = false;
    if (isChordLine(line)) {
        if (!initialChordSet) {
            currentKey = getFirstChordInLine(line);
            initialChordSet = true;
            if (currentKey) {
                currentMasterChord = currentKey.name;
            }
        }
        lineRecognized = true;
        return { "content": line, type: "chord", "chordKey": currentMasterChord };
    }
    // check if this is verse type
    else if (line.charAt(line.length - 1) === "]") {
        switch (line.charAt(1)) {
            case "C": line = line.replace("C", "Chorus ");
                //starting chorus
                thisIsChorusText = true;
                break;
            case "V": line = line.replace("V", "Verse ");
                thisIsChorusText = false;
                break;
            case "B": line = line.replace("B", "Bridge ");
                thisIsChorusText = false;
                break;
            case "I": line = line.replace("I", "Intro ");
                thisIsChorusText = false;
                break;
            case "E": line = line.replace("E", "Ending ");
                thisIsChorusText = false;
                break;
            default: break;
        }
        lineRecognized = true;
        return { "content": line.substring(1, line.length - 1).trim(), type: "verseType" };
    }
    else if (!lineRecognized) {
        for (let j = 0; j < verseTypes.length; j++) {
            if (line.indexOf(verseTypes[j]) !== -1) {
                lineRecognized = true;
                return { "content": line, type: "verseType" };
            }
        }
        if (!lineRecognized) {
            if (thisIsChorusText) {
                return { "content": line, type: "chorus" };
            }
            else {
                return { "content": line, type: "lyrics" };
            }
        }
    }

    if (!initialChordSet) {
        currentKey = getKeyByName("C");
    }
}
/* eslint-enable no-unused-vars */

const transposed = transposeLyricsByMeasure("      F    C   G/E    Am", 1);
console.log(transposed);

module.exports.resolveSongContentStyle = resolveSongContentStyle;
module.exports.isChordLine = isChordLine;
module.exports.transposeLyricsByMeasure = transposeLyricsByMeasure