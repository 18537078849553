import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

export const BackAndAddNavBar = props => {
    const { classes, history } = props;
    return <AppBar position="static">
        <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Back" onClick={history.goBack}>
                <ArrowBackIosIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>PlayLists</Typography>
            <div className={classes.grow} />
            <div className={classes.customAppBar}>
                <IconButton color="inherit"
                    to={`/playlist-maker`}
                    component={Link}>
                    <AddIcon />
                </IconButton>
            </div>
        </Toolbar>
    </AppBar>
}
