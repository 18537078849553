import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SongSelectNavBar } from '../navbars/SongSelectNavBar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlayListMakerItem } from '../lists/PlayListMakerItem';
import TextField from '@material-ui/core/TextField';
import { unixTimeToFormattedDate } from '../../helpers/DateHelper';

const useStyles = makeStyles(theme => ({
    PlayLists: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        //borderBottom: '1px solid #EEE'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    //padding: 0,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging - blueGrey500 : 200
    background: isDragging ? "#b0bec5" : "#546e7a",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
    // blueGrey50 #eceff1 
    background: isDraggingOver ? "#eceff1" : "#eceff1",
    //padding: grid,
    padding: 0,
    paddingTop: 8,
    width: '100%',
});

const PlayListMaker = props => {
    const classes = useStyles();
    const { theme, history, location, playlists } = props;
    const state = location.state;
    const playListId = (state) ? state.playListId : null;
    let playlist = null;
    if (playListId && playlists) {
        playlist = playlists.find(({ id }) => id === playListId);
    }
    const [items, setItems] = useState((playlist) ? playlist.songs : []);

    let unlisten = null;

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { console.log("PlayList Listener teardown"); unlisten(); };
    });

    // teardown
    useEffect(() => {
        return () => { };
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const orderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        setItems(orderedItems);
    }

    const [values, setValues] = React.useState({
        name: (playlist) ? playlist.playListName : "",
        dateCreated: (playlist) ? unixTimeToFormattedDate(playlist.dateCreated) : "",
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

    return (
        <div className={classes.PlayLists}>
            {SongSelectNavBar({ classes, history, theme })}
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="filled-name"
                    label="Playlist Name"
                    value={values.name}
                    onChange={handleChange('name')}
                    helperText={values.dateCreated}
                    margin="normal"
                    variant="filled"
                    fullWidth
                    style={{ margin: 8 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </form>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                                            <PlayListMakerItem {...props} songname={item.songName} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

PlayListMaker.propTypes = {
    //classes: PropTypes.object.isRequired,
    playlists: PropTypes.array
};

export default PlayListMaker;
