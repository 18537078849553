export const compareNames = (a, b) => {
    if (a.songName < b.songName)
        return -1;
    if (a.songName > b.songName)
        return 1;
    return 0;
}

export const compareDates = (a, b) => {
    if (a.dateCreated > b.dateCreated)
        return -1;
    if (a.dateCreated < b.dateCreated)
        return 1;
    return 0;
}

export const nameShortener = (name, ismobilesized) => (name.length > 25 && ismobilesized === 'true') ? `${name.substring(0, 25)}...` : name;

export const getSongContent = (songs, songId) => {
    let songContent = "";
    if (songId) {
        let songIndex = songs.findIndex(({ id }) => id === songId);
        let song = songs[songIndex];
        if (song) {
            songContent = song.songLyrics[0].songLyrics
            return {
                prevSong: (songIndex !== 0) ? songs[songIndex - 1].id : null,
                songContent: songContent.split("\n"),
                songTitle: song.songName.toUpperCase(),
                nextSong: (songIndex !== songs.length - 1) ? songs[songIndex + 1].id : null
            };
        }
    }
    return { prevSong: null, songContent: null, songTitle: null, nextSong: null};
}

export const getPlaylistSongContent = (playlists, playlistId, songId) => {
    let songContent = "";
    if (playlistId) {
        let playlist = playlists.find(({ id }) => id === playlistId);
        if (playlist) {
            const songs = playlist.songs;
            let songIndex = songs.findIndex(({ id }) => id === songId);
            songContent = songs[songIndex].content;
            return {
                prevSong: (songIndex !== 0) ? songs[songIndex - 1].id : null,
                songContent: songContent.split("\n"),
                songTitle: songs[songIndex].songName.toUpperCase(),
                nextSong: (songIndex !== songs.length - 1) ? songs[songIndex + 1].id : null
            };
        }
    }
    return { prevSong: null, songContent: null, songTitle: null, nextSong: null};
}