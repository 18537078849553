import React, { useContext } from 'react'
import DataContext from './context/data-context'

// custom components
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrimarySearchAppBar from './components/navbars/SongListNavBar';
import SongList from './components/screens/SongListScreen';
import Song from './components/screens/SongScreen';
import PlayLists from './components/screens/PlayListsScreen';
import PlayListMaker from './components/screens/PlayListMakerScreen';
import Settings from './components/screens/SettingsScreen';
import { MuiThemeProvider } from '@material-ui/core/styles';

const TheRouter = (props) => {
    const { onSongFilter, onThemeChange, onFontSizeChange } = useContext(DataContext);
    const { theme, shownSongs, playlists, fontSize } = props;
    console.log("The router received Props : " + props)
    console.log(props)
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" render={props =>
                        <div>
                            <PrimarySearchAppBar onSongFilter={onSongFilter} />
                            <SongList {...props} songs={shownSongs} />
                        </div>
                    }
                    />
                    <Route path="/song/:playlist/:id" render={
                        props => <Song {...props} songs={shownSongs} playlists={playlists} fontSize={fontSize}/>
                    } />
                    <Route path="/playlists" render={
                        props => <PlayLists {...props} playlists={playlists} />
                    } />
                    <Route path="/playlist-maker" render={
                        props => <PlayListMaker {...props} playlists={playlists} songs={shownSongs} />
                    } />
                    <Route path="/settings" render={
                        props => <Settings {...props} onThemeChange={onThemeChange} fontSize={fontSize} onFontSizeChange={onFontSizeChange}/>
                    } />
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>)
}

function MainAppPathRouter(props) {
    return <TheRouter {...props} />
}

export default MainAppPathRouter