import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SongView } from '../views/SongView';
import { getSongContent, getPlaylistSongContent } from '../../helpers/SongHelpers'
import Typography from '@material-ui/core/Typography';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import TransposeUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TransposeDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChordsOffIcon from '@material-ui/icons/MusicOff';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import NextIcon from '@material-ui/icons/NavigateNext';
import PreviousIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
    appbar: {
        flexGrow: 1,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(3),
        left: 'auto',
        top: 'auto',
        transform: 'scale(0.8)'
    },
    button: {
        //margin: theme.spacing(),
    },
    label: {
        //margin: theme.spacing(),
        //marginTop: theme.spacing(0.5),
        paddingTop: 14,
        fontFamily: 'Menlo,Monaco,Monospace',
        fontWeight: 'bold',
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

const Song = props => {
    const classes = useStyles();
    const { songs, playlists, match, history, fontSize } = props;
    const [open, setOpen] = useState(false);
    const [hidden] = useState(false);
    const [transpose, setTranspose] = useState(0);
    const [hideChord, setHideChord] = useState(false);
    let unlisten = null;

    const playlistId = match.params.playlist;
    const songId = match.params.id;
    const isPlaylist = playlistId !== "all";

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            console.log(location);
            console.log("ACTION: " + action);
            if (action === 'POP') {
                console.log("isPlaylist: " + isPlaylist);
                history.replace((isPlaylist) ? '/playlists' : '/');
            }
        });
        return () => { console.log("Listener teardown"); unlisten(); };
    });

    const handleClick = () => {
        setOpen(!open);
    };

    const handleOpen = () => {
        if (!hidden) {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const transposeSongUp = () => {
        const transposeUp = transpose + 1;
        setTranspose(transposeUp);
    }

    const transposeSongDown = () => {
        const transposeDown = transpose - 1;
        setTranspose(transposeDown);
    }

    const hideChordsAction = () => {
        const hide = !hideChord;
        console.log("Chord hide: " + hide);
        setHideChord(hide);
    }

    const actions = [
        { icon: <ChordsOffIcon />, name: 'Hide Chords', handleClick: hideChordsAction },
        { icon: <TransposeDownIcon />, name: 'Transpose Down', handleClick: transposeSongDown },
        { icon: <TransposeUpIcon />, name: 'Transpose Up', handleClick: transposeSongUp }
    ];

    const { prevSong, songContent, songTitle, nextSong } = (!isPlaylist) ?
        getSongContent(songs, songId)
        : getPlaylistSongContent(playlists, playlistId, songId);


    const navigateSong = (songId) => {
        history.replace(songId);
    }

    const navigateNext = () => {
        navigateSong(`/song/${playlistId}/${prevSong}`);
    }

    const navigatePrevious = () => {
        navigateSong(`/song/${playlistId}/${nextSong}`);
    }

    const songNavigation = (
        <div style={{ width: '100%' }}>
            <Box display="flex" p={1} >
                <Box p={1} flexShrink={0}>
                    <IconButton variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={navigateNext}
                        disabled={prevSong == null}
                        size="small">
                        <PreviousIcon />
                    </IconButton>
                </Box>
                <Box p={1} width="100%" justifyContent="center" textAlign="center" className={classes.label}>
                    {songTitle || ""}
                </Box>
                <Box p={1} flexShrink={0}>
                    <IconButton variant="contained"
                        color="default"
                        className={classes.button}
                        onClick={navigatePrevious}
                        disabled={nextSong == null}
                        size="small">
                        <NextIcon />
                    </IconButton>
                </Box>
            </Box>
        </div>
    );

    return (
        <div className={classes.root}>
            {songNavigation}
            <Typography component="div">
                <SongView songContent={songContent} fontSize={fontSize} transpose={transpose} hideChord={hideChord} />
            </Typography>
            <IconButton variant="contained"
                color="default"
                className={classes.button}
                to={isPlaylist ? '/playlists' : '/'}
                component={Link}>
                <PreviousIcon />
            </IconButton>
            <Button size="medium" className={classes.label} disableRipple>
                {isPlaylist ? 'PLAYLISTS' : 'SONGS'}
            </Button>

            <SpeedDial
                ariaLabel=""
                className={classes.speedDial}
                hidden={hidden}
                icon={<SpeedDialIcon />}
                onBlur={handleClose}
                onClick={handleClick}
                onClose={handleClose}
                onFocus={handleOpen}
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                open={open}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={action.handleClick}
                    />
                ))}
            </SpeedDial>
        </div>
    );
}

Song.propTypes = {
    //classes: PropTypes.object.isRequired,
    songs: PropTypes.array
};

export default Song;