export const searchSongs = async (query, db) => {
    console.log("Searching by filter: " + query);
    let results = db.chain()
        .find({
            '$or': [{
                'lyrics': {
                    '$regex': [query, 'i']
                }
            }, {
                'name': {
                    '$regex': [query, 'i']
                }
            }]
        })
        .simplesort('name')
        .data()
    return results;
}

export const checkIfSongsInDb = async (db) => {
    let res = db.chain().simplesort("name").data();
    let count = Number(res.lenght);
    console.log("Count of songs now: " + count);
    if (count > 100) {
        return true;
    } else {
        return false;
    }
}