import React from 'react';
import { DataConsumer } from './data-context';
import MainAppPathRouter from '../MainAppPathRouter'

// this context contains all props which will be passed to all components
export default function MainContext() {
  return (
    <DataConsumer>
      {({ shownSongs, theme, onThemeChange, onSongFilter, playlists, fontSize }) => (
        <MainAppPathRouter
          shownSongs={shownSongs}
          playlists={playlists}
          theme={theme}
          onThemeChange={onThemeChange}
          onSongFilter={onSongFilter}
          fontSize={fontSize} />
      )}
    </DataConsumer>
  );
}