import { compareNames, compareDates } from '../helpers/SongHelpers';

export async function fetchSongs(lang) {
    console.log("Fetching songs");
    const songsUrl = process.env.REACT_APP_SONGS_URL;
    let downloadedSongs = await (await fetch(songsUrl)).json();
    if (downloadedSongs['songs']) {
        downloadedSongs = downloadedSongs.songs
    }
    //console.log(downloadedSongs);
    return downloadedSongs.sort(compareNames);
}

export async function fetchPlaylists(lang) {
    console.log("Fetching playlists");
    const playlistsUrl = process.env.REACT_APP_PLAYLISTS_URL;
    let downloadedPlaylists = await (await fetch(playlistsUrl)).json();
    if (downloadedPlaylists['playlists']) {
        downloadedPlaylists = downloadedPlaylists.playlists
    }
    //console.log(downloadedPlaylists);
    return downloadedPlaylists.sort(compareDates);
}