import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../wrappers/withWindowDimensions';
import { VariableSizeList as List } from 'react-window';
import { SongListItem } from '../lists/SongListItem';

const useStyles = makeStyles(theme => ({
    SongList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid #EEE'
    }
}));

const TOOLBAR_HEIGHT = 56;

const SongList = props => {
    const classes = useStyles();
    const { songs, location: { pathname } } = props;
    const { windowHeight, windowWidth, isMobileSized } = useWindowDimensions();
    const listWindow = windowHeight - TOOLBAR_HEIGHT;
    const [selectedSongIndex, setSelectedSongIndex] = useState(0);
    console.log("selectedSongIndex: " + selectedSongIndex);
    console.log(props);

    if (songs && songs.length > 0) {
        return (
            <div className={classes.SongList}>
                <List
                    className="List"
                    height={listWindow}
                    itemCount={songs.length}
                    itemSize={(index) => 50}
                    width={windowWidth}>
                    {({ index, style }) =>
                        <div key={index} style={style}>
                            <SongListItem
                                key={songs[index].id}
                                onClick={(event) => {
                                    //setSelectedSongIndex(index);
                                }}
                                to={`/song/all/${songs[index].id}`}
                                selected={`/song/all/${songs[index].id}` === pathname}
                                songname={songs[index].songName}
                                component={Link}
                                ismobilesized={isMobileSized.toString()} />
                        </div>
                    }
                </List>
            </div>
        );
    } else {
        return (null)
    }
}

SongList.propTypes = {
    //classes: PropTypes.object.isRequired,
    songs: PropTypes.array
};

export default SongList;
