export const unixTimeToFormattedDate = (unixTime) => {
    const date = new Date(unixTime);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = "0" + date.getSeconds();

    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();

    // Will display time in 12/02/2019 10:30:23 format
    const formattedTime = day + "/" +  month + "/" + year + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
}