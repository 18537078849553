import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { nameShortener } from '../../helpers/SongHelpers';

export const SongListItem = props => {
    const { songname, ismobilesized } = props;
    return <ListItem button
        {...props}>
        <ListItemIcon>
            <MusicNoteIcon />
        </ListItemIcon>
        <ListItemText primary={nameShortener(songname, ismobilesized)} />
    </ListItem>
}