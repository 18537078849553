import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from '../wrappers/withWindowDimensions';
import { VariableSizeList as List } from 'react-window';
import PlayListItem from '../lists/PlayListItem';
import { BackAndAddNavBar } from '../navbars/BackAndAddNavBar';

const useStyles = makeStyles(theme => ({
    PlayLists: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        //borderBottom: '1px solid #EEE'
    },
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));

const TOOLBAR_HEIGHT = 56;

const PlayLists = props => {
    const classes = useStyles();
    const { history, playlists } = props;
    const { windowHeight, windowWidth } = useWindowDimensions();
    const listWindow = windowHeight - TOOLBAR_HEIGHT;
    const [rowSizes, setRowSizes] = useState({});
    const [rowOpen, setRowOpen] = useState({});
    const listRef = React.createRef();
    let unlisten = null;

    useEffect(() => {
        unlisten = history.listen((location, action) => {
            console.log("Playlist screen history action: " + action);
            if (action === 'POP') {
                history.replace('/');
            }
        });
        return () => { console.log("PlayList Listener teardown"); unlisten(); };
    });

    const toggleSize = (i, songCount, playListId, open) => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(i);
        }
        const prevRowSizes = rowSizes;
        setRowSizes({
            ...prevRowSizes,
            [i]: (open) ? (((songCount + 1) * 50) + 70) : 70
        })
        const prevRowOpen = rowOpen;
        setRowOpen({
            ...prevRowOpen,
            [playListId]: open
        })
    };

    const getSize = i => (rowSizes[i]) ? rowSizes[i] : 70;

    if (playlists && playlists.length > 0) {
        return (
            <div className={classes.root}>
                {BackAndAddNavBar({ classes, history })}
                <List className="List"
                    ref={listRef}
                    height={listWindow}
                    itemCount={playlists.length}
                    itemSize={getSize}
                    width={windowWidth}>
                    {({ index, style }) =>
                        <div style={style}>
                            <PlayListItem
                                playlist={playlists[index]}
                                playlistIndex={index}
                                toggleSize={toggleSize}
                                history={history}
                                rowOpen={rowOpen[playlists[index].id] ? true : false}
                            />
                        </div>
                    }
                </List>
            </div>
        );
    } else {
        return (null)
    }
}

PlayLists.propTypes = {
    //classes: PropTypes.object.isRequired,
    playlists: PropTypes.array
};

export default PlayLists;
