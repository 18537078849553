// GLOBAL DB
export const initDb = async (songs) => {
    /*
    var db = new loki("playsong.db", {
        autoload: true,
        autoloadCallback: databaseInitialize,
        autosave: true,
        autosaveInterval: 4000
    });
    */
    const loki = require('lokijs');
    const db = new loki("playsong.db");
    return db;
}

// SONGS DB
export const saveSongs = async (songs, db) => {
    const songDb = await loadSongsToDb(songs, db);
    return songDb;
}

// PLAYLISTS DB
export const savePlaylists = async (playlists, db) => {
    const playlistsDb = db.addCollection("playlists", {
        unique: ['id']
    });
    playlists.map(playlist => {
        try {
            playlistsDb.insert({ id: playlist.id, name: playlist.playListName, created: playlist.dateCreated })
        }
        catch (err) {
            let existingPlaylist = playlistsDb.by('id', playlist.id)
            if (existingPlaylist) {
                existingPlaylist.name = playlist.playListName
                existingPlaylist.created = playlist.dateCreated
                console.log('Updating existing playlist')
                playlistsDb.update(existingPlaylist)
            }
        }
        return null;
    })
    return playlistsDb;
}

// SETTINGS
const DEFAULT_SETTINGS_ID = 1;
export const initSettingsDb = async (db) => {
    const settingsDb = db.addCollection("settings", {
        unique: ['id']
    });
    return settingsDb;
}

export const saveSettings = async (settings, settingsDb) => {
    console.log("Saving settings ");
    console.log(settings);
    return upsert(settingsDb, DEFAULT_SETTINGS_ID, { fontSize: settings.fontSize, id: DEFAULT_SETTINGS_ID });
}

export const getSettings = async (settingsDb) => {
    let settings = settingsDb.by('id', DEFAULT_SETTINGS_ID);
    // Initialize default settings
    if (settings) {
        return settings;
    } else {
        await saveSettings({ fontSize: 16}, settingsDb);
        return settingsDb.by('id', DEFAULT_SETTINGS_ID);
    }
}

export const loadSongsToDb = async (songs, db) => {
    //db.removeCollection("songs")
    //if (!db.getCollection("songs")) {
    if (true) {
        console.log("Adding songs")
        const songsDb = db.addCollection("songs", {
            unique: ['id']
        });
        songs.map(song => {
            try {
                songsDb.insert({ id: song.id, name: song.songName, lyrics: song.songLyrics[0].songLyrics })
            }
            catch (err) {
                let existingSong = songsDb.by('id', song.id)
                if (existingSong) {
                    existingSong.name = song.songName
                    existingSong.lyrics = song.songLyrics[0].songLyrics
                    console.log('Updating existing song')
                    songsDb.update(existingSong)
                }
            }
            return null;
        })
        //await databaseReady(songsDb)
        return songsDb;
    }
}

async function databaseReady(songsDb) {
    console.log("databaseReady")
    var query = "Bitka"
    var results = songsDb.chain()
        .find({
            '$or': [{
                'lyrics': {
                    '$regex': [query, 'i']
                }
            }, {
                'name': {
                    '$regex': [query, 'i']
                }
            }]
        })
        .simplesort('name')
        .data()
    //.map(song => song.id)

    console.log(results)
    console.log(results.length)
}


// HELPERS
function upsert(collection, idField, record) {
    let existingRecord = collection.by('id', idField)
    if (existingRecord) {
        console.log("Updating existing record");
        let updatedRecord = existingRecord;
        updatedRecord.fontSize = record.fontSize;
        collection.update(updatedRecord);
    } else {
        console.log("Inserting new record");
        collection.insert(record);
    }
    return collection.by('id', idField);
}
