import React, { createContext, useEffect, useState } from 'react';
import { fetchSongs, fetchPlaylists } from '../api/playsongservice';
import { initDb, saveSongs, savePlaylists, initSettingsDb, saveSettings, getSettings } from '../db/operations.js';
import { searchSongs } from '../db/queries.js';
import { createMuiTheme } from '@material-ui/core/styles';
import '../App.css';

import lightTheme from '../theme/light';
import darkTheme from '../theme/dark';

const themeLight = createMuiTheme(lightTheme);
const themeDark = createMuiTheme(darkTheme);

const DataContext = createContext({
  onThemeChange: () => { },
  onSongFilter: () => { },
  onFontSizeChange: () => { }
});

export function DataProvider(props) {
  const [songs, setSongs] = useState([]);
  const [shownSongs, setShownSongs] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [isThemeLight, setIsThemeLight] = useState(false);
  const [theme, setTheme] = useState(themeLight);
  const [db, setDb] = useState(null);
  const [songDb, setSongDb] = useState(null);
  const [playlistsDb, setPlaylistsDb] = useState(null);
  const [settingsDb, setSettingsDb] = useState(null);
  const [fontSize, setFontSize] = useState();

  const onThemeChange = () => {
    setIsThemeLight((isThemeLight) ? false : true)
    setTheme((isThemeLight) ? themeLight : themeDark)
  }

  const onFontSizeChange = async (value) => {
    setFontSize(value);
    await saveSettings({ fontSize: value }, settingsDb);
  }

  const onSongFilter = async (filter) => {
    console.log("Search by filter: " + filter);
    if (songDb) {
      let searchedSongs = await searchSongs(filter, songDb);
      if (searchedSongs) {
        let ids = [];
        searchedSongs.map(song => ids.push(song.id));
        let filteredSongs = songs.filter((el) => {
          return ids.includes(el.id);
        });
        console.log("Filtered songs: " + filteredSongs.length);
        setShownSongs(filteredSongs)
      }
    }
    else {
      console.log("Local song db not initalized");
    }
  }

  const _onAppStartup = async () => {
    console.log("_onAppStartup");
    const localDb = await initializeDatabase();
    if (localDb) {
      await initSettings(localDb);
      await initSongs(localDb)
      await initPlaylists(localDb)
    }
  }

  const initializeDatabase = async () => {
    const localDb = await initDb();
    if (localDb) {
      setDb(localDb)
      return localDb;
    } else {
      return null;
    }
  }
  const initSongs = async (localDb) => {
    const fetchedSongs = await fetchSongs();
    setSongs(fetchedSongs);
    setShownSongs(fetchedSongs);
    const localSongDb = await saveSongs(fetchedSongs, localDb);
    if (localSongDb) {
      setSongDb(localSongDb)
    }
  }

  const initPlaylists = async (localDb) => {
    const fetchedPlaylists = await fetchPlaylists();
    setPlaylists(fetchedPlaylists);
    const localPlaylistsDb = await savePlaylists(fetchedPlaylists, localDb);
    if (localPlaylistsDb) {
      setPlaylistsDb(localPlaylistsDb)
    }
  }

  const initSettings = async (localDb) => {
    const localSettingsDb = await initSettingsDb(localDb);
    console.log("localSettingsDb " + localSettingsDb);
    if (localSettingsDb) {
      const settings = await getSettings(localSettingsDb);
      console.log("Reading stored settings: " + settings);
      if (settings) {
        console.log("Settings fontSize")
        console.log(settings);
        console.log("Settings fontSize: " + settings.fontSize);
        setFontSize(settings.fontSize);
      }
      setSettingsDb(localSettingsDb);
    }
  }

  useEffect(() => {
    _onAppStartup(); // started automatically at UI startup (page refresh)
  }, []);

  return (
    <DataContext.Provider value={{ shownSongs, playlists, theme, onThemeChange, onSongFilter, onFontSizeChange, fontSize }}>
      {props.children}
    </DataContext.Provider>
  );

}

export const DataConsumer = DataContext.Consumer;
export default DataContext;
