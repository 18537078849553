import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import BrightnessIcon from '@material-ui/icons/BrightnessMedium';
import { BackNavBar } from '../navbars/BackNavBar';
import Slider from '@material-ui/lab/Slider';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  slider: {
    padding: '22px 0px',
  },
}));

const Settings = props => {
  const classes = useStyles();
  const { onThemeChange, history, fontSize, onFontSizeChange } = props;
  console.log("Settings props: ");
  console.log(props);
  const [darkThemeChecked, setDarkThemeChecked] = useState(['darktheme']);
  //const [fontSize, setFontSize] = useState(16);

  const handleToggle = (value, onThemeChange) => () => {
    const currentThemeIndex = darkThemeChecked.indexOf(value);
    const newThemeChecked = [...darkThemeChecked];
    (currentThemeIndex === -1) ? newThemeChecked.push(value) : newThemeChecked.splice(currentThemeIndex, 1);
    setDarkThemeChecked(newThemeChecked)
    console.log(darkThemeChecked)
    onThemeChange();
  };

  const handleFontSizeChange = (event, value) => {
    console.log(value);
    onFontSizeChange(value);
  }

  return (
    <div className={classes.root}>
      {BackNavBar({ classes, history })}
      <List subheader={<ListSubheader>Font size</ListSubheader>} className={classes.root}>
      <ListItem>
          <ListItemIcon>
            <Box fontSize={fontSize} fontWeight={600} m={1}>
              A
        </Box>
          </ListItemIcon>
          <Slider
            classes={{ container: classes.slider }}
            value={fontSize}
            min={8}
            max={32}
            step={2}
            onChange={handleFontSizeChange}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BrightnessIcon />
          </ListItemIcon>
          <ListItemText primary="Dark Theme" />
          <ListItemSecondaryAction>
            <Switch
              onChange={handleToggle('darktheme', onThemeChange)}
              checked={darkThemeChecked.indexOf('darktheme') !== -1}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>

    </div>
  );
}

Settings.propTypes = {
  //classes: PropTypes.object.isRequired,
};

export default Settings;