import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

export const PlayListMakerItem = props => {
    const { songname } = props;
    const secondary = "";
    return (
        <ListItem ContainerComponent="div">
            <ListItemText
                primary={songname}
                secondary={secondary ? 'Secondary text' : null}
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Delete">
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>)
}