import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PlayListIcon from '@material-ui/icons/PlaylistPlay';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MusicIcon from '@material-ui/icons/MusicNote';
import { unixTimeToFormattedDate } from '../../helpers/DateHelper';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

const PlayListItem = props => {
    const classes = useStyles();
    const { playlist, toggleSize, playlistIndex, rowOpen, history } = props;
    const [open, setOpen] = useState((rowOpen) ? false : true);

    const handleClick = (index, songCount, playListId) => {
        setOpen(!open);
        toggleSize(index, songCount, playListId, open);
    }

    const onEditClick = (id) => {
        history.push('/playlist-maker', { playListId: id });
    }

    const songCount = playlist.songs.length;
    const playlistId = playlist.id;

    return (
        <List
            component="nav"
            className={classes.root}
            key={playlistId}>
            <ListItem button onClick={() => handleClick(playlistIndex, songCount, playlistId)}>
                <ListItemIcon>
                    <PlayListIcon />
                </ListItemIcon>
                <ListItemText primary={playlist.playListName} secondary={unixTimeToFormattedDate(playlist.dateCreated)} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <List component="div" disablePadding>
                {playlist.songs.map((song) =>
                    <ListItem button
                        className={classes.nested}
                        key={song.id}
                        to={`/song/${playlistId}/${song.id}`}
                        component={Link}>
                        <ListItemIcon>
                            <MusicIcon />
                        </ListItemIcon>
                        <ListItemText primary={song.songName} />
                    </ListItem>
                )}
                <Button variant="outlined" className={classes.button} onClick={() => onEditClick(playlistId)}>
                    EDIT
                </Button>
                <Button variant="outlined" color="secondary" className={classes.button}>
                    DELETE
                </Button>
            </List>
        </List>
    );
}

export default PlayListItem;