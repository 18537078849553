import React from 'react';
//import logo from './logo.svg';
import { DataProvider } from './context/data-context'
import MainContext from './context/consumer-context'

// App is main application container
export default function App() {
  return (
    <DataProvider>
      <MainContext />
    </DataProvider>
  )
}