import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import SongSelect from '../views/SongSelect';

export const SongSelectNavBar = props => {
    const { classes, history } = props;
    return <AppBar position="static">
        <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Back" onClick={history.goBack}>
                <ArrowBackIos />
            </IconButton>
            <SongSelect {...props}/>
        </Toolbar>
    </AppBar>
}
