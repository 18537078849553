import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PlayListAddIcon from '@material-ui/icons/PlaylistAdd';
import PlayListPlayIcon from '@material-ui/icons/PlaylistPlay';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

export const sideList = props => {
    const { classes } = props;
    return <div className={classes.list}>
        <List>
            <ListItem button key={'Songs'}>
                <ListItemIcon><MusicNoteIcon /></ListItemIcon>
                <ListItemText primary={'Songs'} />
            </ListItem>
            <ListItem button key={'Playlists'} to={'/playlists'} component={Link}>
                <ListItemIcon><PlayListPlayIcon /></ListItemIcon>
                <ListItemText primary={'Playlists'} />
            </ListItem>
            <ListItem button key={'Playlist Maker'} to={'/playlist-maker'} component={Link}>
                <ListItemIcon><PlayListAddIcon /></ListItemIcon>
                <ListItemText primary={'Playlist Maker'} />
            </ListItem>
            <Divider />
            <ListItem button key={'Settings'} to={'/settings'} component={Link}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary={'Settings'} />
            </ListItem>
        </List>
    </div>
};