import React from 'react';
import Box from '@material-ui/core/Box';
import { resolveSongContentStyle, transposeLyricsByMeasure } from '../../helpers/ChordHelper';
import grey from '@material-ui/core/colors/grey';
import brown from '@material-ui/core/colors/brown';
import indigo from '@material-ui/core/colors/indigo';

export const SongView = (props) => {
    const { songContent, fontSize, transpose, hideChord } = props;
    console.log("SongView Transpose : " + transpose);
    console.log("SongView hideChord : " + hideChord);

    const chordColor = indigo[500];
    const verseTypeColor = brown[500];
    const chorusColor = grey[500];
    const lyricsColor = grey[500];

    if (songContent === "" || !songContent || songContent == null) return songContent;

    console.log("Song view font size: " + fontSize);

    const getTransposedContent = (content, transpose) => (transpose !== 0) ? transposeLyricsByMeasure(content, transpose) : content;

    return songContent.map((value, index) => {
        const { content, type } = resolveSongContentStyle(value);
        switch (type) {
            case "chord":
                if (hideChord) return (null);
                return (<Box fontFamily="Menlo,Monaco,Monospace" fontWeight={'bold'} fontSize={fontSize} m={1} key={index} style={{ lineHeight: 1.0, whiteSpace: "pre", color: chordColor }}>
                    {getTransposedContent(content, transpose)}
                </Box>)
            case "verseType":
                return (<Box fontFamily="Menlo,Monaco,Monospace" fontWeight={'normal'} fontSize={fontSize} m={1} key={index} style={{ lineHeight: 1.0, whiteSpace: "pre", color: verseTypeColor }}>
                    {content}
                </Box>)
            case "chorus":
                return (<Box fontFamily="Menlo,Monaco,Monospace" fontWeight={'bold'} fontSize={fontSize} m={1} key={index} style={{ lineHeight: 1.0, whiteSpace: "pre", color: chorusColor }}>
                    {content}
                </Box>)
            case "lyrics":
                return (<Box fontFamily="Menlo,Monaco,Monospace" fontWeight={'normal'} fontSize={fontSize} m={1} key={index} style={{ lineHeight: 1.0, whiteSpace: "pre", color: lyricsColor }}>
                    {content}
                </Box>)
            default: return (null);
        }
    });
}